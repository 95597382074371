import { render, staticRenderFns } from "./Campaign.table.vue?vue&type=template&id=df2b87e8&scoped=true&"
import script from "./Campaign.table.vue?vue&type=script&lang=js&"
export * from "./Campaign.table.vue?vue&type=script&lang=js&"
import style0 from "./Campaign.table.vue?vue&type=style&index=0&id=df2b87e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2b87e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VDataTable,VIcon,VTextField,VTooltip})
