<template>
	<div class="organization tw-p-[24px]">
		<HeaderSlot
		:title="'Administration de ' + organization.name"
		subTitle="Gestion de l'organisation"
		@clickBackButton="$router.back()"
		>
			<ButtonSlot
			@click="deactivateSiteFromOrganisation()"
			v-if="$hasRight('sites.edit')"
			>
				Gérer l'accès
			</ButtonSlot>

			<HighButtonSlot
			v-if="$hasRight('organizations.changeInfos')"
			@click="openDeleteOrganizationModale()"
			>
				Supprimer l'organisation
			</HighButtonSlot>
		</HeaderSlot>

		<div class="scrollable_container pa-1 pr-4">
			<v-card v-if="$hasRight('organizations.changeInfos')">
				<v-card-title>
					<h3>Informations de l'organisation</h3>
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col cols="5">
							<v-text-field
							v-if="isOrganizationEditMode"
							outlined
							clearable
							label="Nom"
							dense
							v-model="newOrganizationName"
							/>

							<p v-else>
								Nom : {{ organization.name }}
							</p>

							<v-checkbox
							color="#2a7221"
							v-if="
								$hasRight('organizations.manageAll') && isOrganizationEditMode
							"
							label="Organisation bureau d'étude"
							v-model="newOrganizationIsInternal"
							/>

							<p
							class="mt-4"
							v-else
							>
								Bureau d'étude : {{ organization.isInternal ? "oui" : "non" }}
							</p>
						</v-col>

						<v-col cols="2"/>

						<v-col
						cols="5"
						align="center"
						>
							<v-col>
								<v-row justify="center">
									<div class="center-p">
										<p class="mr-2 mb-0">
											Logo de l'organisation qui<br/>
											apparaitra sur vos livrables :
										</p>
									</div>

									<v-img
									class="logo_image mb-5"
									:src="newOrganizationLogo"
									contain
									/>
								</v-row>
							</v-col>

							<div>
								<v-file-input
								v-if="isOrganizationEditMode"
								class="mt-0 pt-0"
								type="file"
								accept=".png, .jpg, .jpeg"
								v-model="uploadedImg"
								label="Ajouter un logo"
								@change="inputFile"
								/>
							</div>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<MediumButtonSlot
					v-if="isOrganizationEditMode"
					@click="isOrganizationEditMode = !isOrganizationEditMode"
					>
						Annuler
					</MediumButtonSlot>

					<v-spacer/>

					<HighButtonSlot
					v-if="isOrganizationEditMode"
					@click="saveOrganization"
					>
						Valider
					</HighButtonSlot>

					<v-icon
					v-if="!isOrganizationEditMode"
					color="#e89005"
					@click="isOrganizationEditMode = !isOrganizationEditMode"
					>
						{{ "mdi-pen" }}
					</v-icon>
				</v-card-actions>
			</v-card>

			<SiteTable
			@refreshData="refreshViewData()"
			:sites="sites"
			:organization="organization"
			/>

			<UserTable
			@refreshData="refreshViewData()"
			:users="organizationUsers"
			:organization="organization"
			/>

			<CampaignTable :campaigns="campaigns"/>
		</div>

		<OrganizationDeleteModale
		v-if="isDeleteOrgaModaleDisplayed"
		:organization="organization"
		@closePopin="closeOrganizationDeleteModale()"
		/>

		<SiteDeactivationModal
		v-if="isOpenDeactivationModal"
		@closeCurrent="isOpenDeactivationModal = false"
		type="organization"
		:siteOrOrganization="siteOrOrganization"
		/>
	</div>
</template>

<script>
import UserTable from "@/components/administration/dashboard/User.table.vue";
import SiteTable from "@/components/administration/dashboard/Site.table.vue";
import CampaignTable from "@/components/administration/dashboard/Campaign.table.vue";
import OrganizationDeleteModale from "@/components/entities/organization/DeleteOrganization.modal";
import SiteDeactivationModal from "../../components/administration/dashboard/SiteDeactivationModal.vue";

export default {
	components: {
		SiteTable,
		UserTable,
		CampaignTable,
		OrganizationDeleteModale,
		SiteDeactivationModal
	},
	data(){
		return {
			isDeleteOrgaModaleDisplayed: false,
			isOrganizationEditMode: false,

			organization: {
				name: ""
			},
			newOrganizationName: "",
			newOrganizationIsInternal: false,
			newOrganizationLogo: null,

			currentLogoPath: null,
			isItAnUpload: false,
			uploadedImg: null,
			logoSource: null,
			organizationUsers: [],
			sites: [],
			campaigns: [],
			isOpenDeactivationModal: false,
			siteOrOrganization: false
		};
	},
	methods: {
		inputFile(e){
			if(e === null){
				this.newOrganizationLogo = this.logoSource;
			}
			else {
				this.newOrganizationLogo = URL.createObjectURL(e);
			}
		},
		openDeleteOrganizationModale(){
			this.isDeleteOrgaModaleDisplayed = true;
		},
		closeOrganizationDeleteModale(){
			this.isDeleteOrgaModaleDisplayed = false;
		},
		saveOrganization(){
			if(this.uploadedImg !== null){
				var formData = new FormData();
				formData.append("file", this.uploadedImg);
				this.$api.organizations.uploadFile(formData).then(data => {
					this.$api.organizations
					.edit(this.organization.id, {
						name: this.newOrganizationName,
						isInternal: this.newOrganizationIsInternal,
						logoPath: data
					})
					.then(() => {
						this.currentLogoPath = data;
						this.isItAnUpload = true;
						this.refreshViewData();
						this.uploadedImg = null;
					});
				});
			}
			else {
				this.$api.organizations
				.edit(this.organization.id, {
					name: this.newOrganizationName,
					isInternal: this.newOrganizationIsInternal,
					logoPath: this.currentLogoPath
				})
				.then(() => {
					this.refreshViewData();
				});
			}
			this.isOrganizationEditMode = false;
		},
		async getSites(){
			this.sites = await this.$api.organizations.getAllSitesFromOrganization(
				this.$route.params.id
			);
		},
		async getOrganization(){
			this.organization = await this.$api.organizations.findById(
				this.$route.params.id
			);
			this.newOrganizationName = this.organization.name;
			this.newOrganizationIsInternal = this.organization.isInternal;
			this.currentLogoPath = this.organization.logoPath;

			if(this.isItAnUpload === false){
				this.getOrganizationLogo();
			}
		},
		getOrganizationLogo(){
			if(this.organization.logoPath !== null){
				this.$api.organizations.getFileUrl(this.$route.params.id).then(data => {
					this.newOrganizationLogo = data;
					this.logoSource = data;
				});
			}
		},
		async getOrganizationUsers(){
			const users = await this.$api.users.findByOrganization(
				this.$route.params.id
			);

			this.organizationUsers = users.map(user => {
				const userRoleIndex = user.roles.findIndex(
					role => role === "ROLE_USER"
				);

				user.roles.splice(userRoleIndex, 1);

				return user;
			});
		},
		async getCampaigns(){
			const rawCampaigns = await this.$api.campaigns.findByUser(
				this.$store.state.user.current.userId,
				true
			);

			this.campaigns = this.organization.isInternal
				? rawCampaigns.filter(
					campaign =>
						campaign.workspace.site.organization.id === this.organization.id
				)
				: rawCampaigns.filter(
					campaign =>
						campaign.workspace.customerSite.organization.id ===
              this.organization.id
				);

			this.campaigns.sort((a, b) => a.name.localeCompare(b.name));
		},
		refreshViewData(){
			if(undefined === this.$store.state.user.current?.userId) return setTimeout(this.refreshViewData, 200);
			if(!this.$hasRight("organizations.manageOne")) this.$router.push("/workspaces");
			this.getOrganization();
			this.getSites();
			this.getCampaigns();
			this.getOrganizationUsers();
		},
		async deactivateSiteFromOrganisation(){
			this.sites.length > 1 ? 
				this.siteOrOrganization = this.organization
				: this.siteOrOrganization = this.sites[0];
			this.isOpenDeactivationModal = true;
			//this.sites = await this.$api.organizations.deactivateAllSites(this.organization.id);
		}
	},
	mounted(){
		this.refreshViewData();
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.organization {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .d-flex,
  .d-flex .left {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.actionBtn {
  cursor: pointer;
  margin: 5px;
}
.TableCard {
  margin: 25px 0;

  .v-data-footer .v-btn > .v-btn__content .v-icon {
    color: rgb(150, 150, 150) !important;
  }

  .search-field {
    margin-right: 10px;
    width: 24%;
  }
  .search-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .add-field {
    display: flex;
    flex-flow: row-reverse;
    flex-grow: 1;
  }

  .table-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .table-action {
      cursor: pointer;
      margin: 5px;
    }
  }
}

.logo_image {
  max-width: 136px;
  height: 136px;
  border: 1px rgba(0, 0, 0, 0.4) solid;
  border-radius: 4px;
  transition: border 0.2s ease;
}

.logo_filename {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.center-p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 136px;
}
</style>
