<template>
	<section class="siteDeactivationModal">
		<v-card
		flat
		outlined
		class="siteDeactivationModal__card"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Gérer l'accès</h3>
			</v-card-title>

			<div class="tw-flex tw-w-full tw-basis-full">
				<div
				class="tw-flex tw-gap-[10px] tw-flex-row tw-w-full spaceBetween"
				>
					<div class="tw-flex tw-flex-col">
						<div class="tw-flex tw-flex-row mt-8">
							Statut :&nbsp;<v-chip
							v-if="siteOrOrga.deactivation === undefined ? false : siteOrOrga.deactivation !== null && new Date(siteOrOrga.deactivation) < new Date()"
							color="red"
							text-color="white"
							small
							>
								Accès suspendu
							</v-chip>

							<v-chip
							v-if="siteOrOrga.deactivation === undefined ? false : siteOrOrga.deactivation === null || new Date(siteOrOrga.deactivation) > new Date()"
							color="green"
							text-color="white"
							small
							>
								Accès actif
							</v-chip>

							<v-chip
							v-if="siteOrOrga.deactivation === undefined"
							color="orange"
							text-color="white"
							small
							>
								Dépendant des sites
							</v-chip>
						</div>

						<div
						class="mt-4"
						v-if="siteOrOrga.deactivation === undefined ? false : siteOrOrga.deactivation !== null"
						>
							Désactivation le : <b>{{ moment(siteOrOrga.deactivation).format('DD/MM/Y') }} ({{ - moment().diff(siteOrOrga.deactivation, 'days') }} jours restants)</b>
						</div>
					</div>
				</div>

				<div class="tw-flex tw-flex-col tw-gap-[10px] width"> 
					<p style="height: 0px;">
						{{ isSuspendingAccess === true ? 'Suspendre l\'accès ?' : '' }}
						{{ isReabilitatingAccess === true ? 'Rétablir l\'accès ?' : '' }}
					</p>

					<div
					class="tw-flex tw-flex-row tw-gap-[10px] spaceBetween"
					v-if="siteOrOrga.deactivation !== undefined 
						? siteOrOrga.deactivation === null ? true : new Date(siteOrOrga.deactivation) > new Date() 
						: true"
					>
						<ButtonSlot
						@click="isSuspendingAccess = true"
						v-if="isSuspendingAccess === false"
						>
							Suspendre l'accès
						</ButtonSlot>

						<ButtonSlot
						@click="isSuspendingAccess = false"
						v-if="isSuspendingAccess === true"
						_theme="light-gray"
						>
							Non
						</ButtonSlot>

						<ButtonSlot
						@click="patchActivation(new Date())"
						v-if="isSuspendingAccess === true"
						>
							oui
						</ButtonSlot>
					</div>

					<div
					class="tw-flex tw-flex-row tw-gap-[10px] spaceBetween"
					v-else
					>
						<ButtonSlot
						@click="isReabilitatingAccess = true"
						v-if="isReabilitatingAccess === false"
						>
							Rétablir l'accès
						</ButtonSlot>

						<ButtonSlot
						@click="isReabilitatingAccess = false"
						v-if="isReabilitatingAccess === true"
						_theme="light-gray"
						>
							Non
						</ButtonSlot>

						<ButtonSlot
						@click="patchActivation(null)"
						v-if="isReabilitatingAccess === true"
						>
							Oui
						</ButtonSlot>
					</div>

					<ButtonSlot
					@click="isChoosingExpirationDate = true"
					v-if="isChoosingExpirationDate === false && siteOrOrga.deactivation === null"
					>
						Choisir la date d'expiration
					</ButtonSlot>

					<div
					class="tw-flex tw-flex-row tw-gap-[10px] spaceBetween"
					v-else
					>
						<ButtonSlot
						@click="isDeletingExpirationDate = true"
						v-if="isChoosingExpirationDate === false && siteOrOrga.deactivation !== null && isDeletingExpirationDate === false"
						_theme="light-gray"
						>
							Supprimer la date d'expiration
						</ButtonSlot>

						<ButtonSlot
						@click="isDeletingExpirationDate = false"
						v-if="isDeletingExpirationDate === true"
						_theme="light-gray"
						>
							Non
						</ButtonSlot>

						<ButtonSlot
						@click="patchActivation(null)"
						v-if="isDeletingExpirationDate === true"
						>
							Oui
						</ButtonSlot>
					</div>

					<v-menu
					v-if="isChoosingExpirationDate === true"
					transition="scale-transition"
					offset-y
					min-width="auto"
					:close-on-content-click="false"
					ref="menu"
					v-model="menu"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-combobox
							class="comboBoxWidth"
							label="Date d'expiration"
							tabindex="5"
							prepend-inner-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
							outlined
							dense
							append-icon=""
							v-model="expirationDateInput"
							hide-details
							clearable
							>
								<template v-slot:selection>
									{{ moment(expirationDateInput).format("Do MMMM YYYY") }}
								</template>
							</v-combobox>
						</template>

						<v-date-picker
						no-title
						scrollable
						@change="menu = false"
						v-model="expirationDateInput"
						/>
					</v-menu>

					<div class="tw-flex tw-flex-row tw-gap-[10px] spaceBetween">
						<ButtonSlot
						_theme="light-gray"
						@click="isChoosingExpirationDate = false"
						v-if="isChoosingExpirationDate === true"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot
						@click="patchActivation(expirationDateInput)"
						v-if="isChoosingExpirationDate === true"
						:_disabled="expirationDateInput === null"
						>
							Valider
						</ButtonSlot>
					</div>
				</div>
			</div>

			<v-card-actions>
				<ButtonSlot
				_theme="light-gray"
				@click="closePopin"
				>
					Fermer
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
export default {
	name: "SiteDeactivationModal",
	props: {
		siteOrOrganization: {
			default: false
		},
		type: {
			default: false
		}
	},
	data(){
		return {
			statusSet: false,
			expirationDate: null,
			expirationDateInput: null,
			isSuspendingAccess: false,
			isChoosingExpirationDate: false,
			siteOrOrga: this.siteOrOrganization,
			menu: false,
			isDeletingExpirationDate: false,
			isReabilitatingAccess: false
		};
	},
	methods: {
		closePopin(){
			this.$emit("closeCurrent", false);
		},
		patchActivation(date){
			if(this.type === "site"){
				this.$api.sites.patchActivation(this.siteOrOrga.id, {date: date})
				.then((response) => {
					this.siteOrOrga = response;
					this.expirationDate = response.deactivation;
					this.isChoosingExpirationDate = false;
					this.isSuspendingAccess = false;
					this.expirationDateInput = null;
					this.isDeletingExpirationDate = false;
					this.isReabilitatingAccess = false;
					this.$emit("refreshSite");
				});
			}
			else {
				this.$api.organizations.deactivateAllSites(this.siteOrOrga.id, {date: date}).then((response => {
					this.siteOrOrga.deactivation = response[0].deactivation;
					this.expirationDate = response.deactivation;
					this.isChoosingExpirationDate = false;
					this.isSuspendingAccess = false;
					this.expirationDateInput = null;
					this.isDeletingExpirationDate = false;
					this.isReabilitatingAccess = false;
					this.$emit("refreshSite");
				}));
			}
		}

	},
	mounted(){
		if(this.type === "site"){
			this.statusSet = true;
		}
		this.$root.$on("closeCurrent", this.closePopin);
	}
};
</script>

<style lang="scss" scoped>

@import "@/assets/scss/_extends.scss";

.siteDeactivationModal {
  @extend %popinView;
  .spaceBetween {
	justify-content:space-between;
  }

  .width {
	width: 300px !important;
  }

  .comboBoxWidth {
	width: 274px;
  }

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 40%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .v-input--selection-controls {
	margin-top: 0px !important;
  }

  
}

</style>
